<template>
  <section class="invoice-preview-wrapper">

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-0">

              <!-- Header: Left Content -->
              <div class="d-flex">
                <div class="logo-wrapper">
                  <b-img
                    :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                    alt="logo"
                  />
                  <h3 class="text-primary invoice-logo ml-0">
                    {{ appName }}
                  </h3>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">#{{ formData.invoiceNo }}</span>
                </h4>
                <div class="invoice-date-wrapper mb-0">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ formData.issueDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Due Date:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dueDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <div class="invoice-spacing d-flex justify-content-between">
              <!-- Col: Invoice To -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice From:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.systemSettings.company_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address1 }}
                </p>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address2 }}
                </p>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.vatno }}
                </p>
              </div>

              <!-- Col: Payment Details -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.client }}
                </h6>
                <p class="card-text mb-25 invoice-to-address">
                  {{ formData.address + ', ' + formData.country.name }}
                </p>
                <p class="card-text mb-25">
                  Tax-ID/VAT: {{ formData.tax }}
                </p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="formData.invoiceData.itemsFormatted"
            :fields="['itemTitle', 'qty', 'unitPrice', 'itemPrice']"
          >

            <template #head(itemTitle)>
              <span>Item</span>
            </template>

            <template #head(unitPrice)>
              <span>Unit Price</span>
            </template>

            <template #head(itemPrice)>
              <span>Item Price</span>
            </template>

            <template #cell(itemTitle)="data">
              <b-card-text class="font-weight-bold">
                {{ data.value.text ? data.value.text : data.value }}
              </b-card-text>
            </template>

          </b-table-lite>

          <p
            v-if="formData.periodDate.startDate"
            class="w-100 invoice-period"
          >
            Period: {{ formData.periodDate.startDate + ' - ' + formData.periodDate.endDate }}
          </p>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Total -->
              <b-col
                cols="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency }} {{ parseFloat(formData.subTotal).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      VAT ({{ formData.vatPercentage }}%):
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency }} {{ parseFloat(formData.vat).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency }} {{ parseFloat(formData.totalToPay).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding text-center pt-0">
            <p v-if="formData.existingClients">
              Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
            </p>
            <p v-else>
              Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
            </p>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="createInvoice"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </span>
            <span v-else>Save</span>
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            @click="goBack"
          >
            Go Back
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BButton, BImg, BCardText, VBToggle, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BButton,
    BImg,
    BCardText,
    BSpinner,
  },
  props: {
    formData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sending: false,
      appName: null,
      invoiceDescription: [
        {
          taskTitle: 'TrafficManager Agency Package',
          qty: '1.00',
          unit: '€ 499.00',
          item: '€ 499.00',
        },
        {
          taskTitle: 'Period: 2021-07-01 - 2021-07-31',
          qty: '',
          unit: '',
          item: '',
        },
      ],
      status: 'Paid',
      invoiceNos: {
        v1: 0,
        v2: 0,
      },
      packageList: [],
    }
  },
  created() {
    if (this.formData == null) {
      this.$router.push('/billing/invoices-register')
    }
    this.appName = $themeConfig.app.appName
    this.invoiceNos = this.formData.invoiceNos
    this.packageList = this.formData.packageList
  },
  methods: {
    createInvoice() {
      if (this.formData.requiredFields === true) {
        this.notify({
          text: 'Please complete the invoice form before you save it. Please press the Go Back button.',
          variant: 'danger',
        })
        return
      }

      if (this.formData.invoiceData.hasPackageItem === false) {
        this.notify({
          text: 'Please add a package or custom item. Please press the Go Back button.',
          variant: 'danger',
        })
        return
      }

      this.sending = true

      const tempItem = this.formData.invoiceData.items.find(obj => obj.type === 'package')
      this.invoiceNo = this.formData.existingClients ? this.invoiceNos.v1 : `TMC${this.invoiceNos.v2.toString().padStart(4, '0')}`

      const customData = []
      this.formData.invoiceData.items.forEach(item => {
        if (item.type === 'custom') customData.push(item)
      })

      // eslint-disable-next-line no-unused-vars
      const payload = {
        id: Number(this.formData.id),
        currency: this.formData.currency,
        packageName: tempItem ? tempItem.itemTitle.text : null,
        package: tempItem ? this.packageList[tempItem.itemTitle.index].id : null,
        packageQty: tempItem ? tempItem.qty : null,
        packageUnitPrice: tempItem ? tempItem.unitPrice : null,
        packageItemPrice: tempItem ? tempItem.itemPrice : null,
        periodStart: this.formData.periodDate.startDate,
        periodEnd: this.formData.periodDate.endDate,
        subtotal: this.formData.subTotal,
        vatPercent: this.formData.vatPercentage,
        vatValue: this.formData.vat,
        total: this.formData.totalToPay,
        bankingProfile: this.formData.bankDetails ? this.formData.bankDetails.id : null,
        version: this.formData.existingClients ? 1 : 2,
        invoice_number_v2: this.formData.existingClients ? 0 : this.formData.invoiceNo,
        issueDate: this.formData.issueDate,
        dueDate: this.formData.dueDate,
        to: this.formData.client,
        userAddress: this.formData.address,
        userCountry: this.formData.country.code,
        userVAT: this.formData.tax,
        totalText: null,
        totalValue: null,
        uid: this.formData.invoiceTo.value,
        invoiceData: customData,
      }

      let dispatchURL = 'invoice/updateCustomInvoice'
      if (this.formData.isNew === true) dispatchURL = 'invoice/storeNewInvoice'

      this.$store.dispatch(dispatchURL, payload)
        .then(res => {
          if (res) {
            this.sending = false
            this.notify({
              text: 'Invoice created successfully!',
              variant: 'success',
            })
            this.$router.push(`/custom-invoices/${res.id}`)
          }
        })
    },
    goBack() {
      const editRoutePath = `/custom-invoices/${this.formData.id}/edit`
      const createRouteName = '/custom-invoices/create'

      if (this.formData.method === 'create') this.$router.push({ path: createRouteName, params: { previewData: this.formData } })
      else this.$router.push({ path: editRoutePath, params: { previewData: this.formData } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}
.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.invoice-period {
  padding: 10px 29px;
  border-top: 1px solid #e7e7e7;
  font-weight: 500;
  font-size: 14px;
  margin-top: -1rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
